import React, { useContext } from "react"
import { Link } from "gatsby"
import CurrencyFormatter from "./CurrencyFormatter"
import CartContext from "../../context/CartProvider"

const CartWrapper = ({ cartType }) => {
  const value = useContext(CartContext)
  const updateCartItemQuantity = value?.updateCartItemQuantity
  const removeItemFromCart = value?.removeItemFromCart
  const state = value?.state
  const updatingItem = state?.updatingItem
  const cartLoading = state?.cartLoading
  const numberItems = state?.cart?.numberItems
  const lineItems = state?.cart?.lineItems
  const currency = state?.cart?.currency?.code
  const cartAmount = state?.cart?.cartAmount

  if (cartLoading) {
    return <h4 className="text-center">Loading..</h4>
  }

  if (numberItems === 0) {
    return (
      <div className="text-center">
        <h2>Your cart is empty.</h2>
        <Link to="/" className="btn btn-link">
          Take a look around.
        </Link>
      </div>
    )
  }

  return (
    <div>
      {cartType === "full" && <h1>Your Cart</h1>}
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col-md-6">Items</div>
          <div className="col-md-2">Quantity</div>
          <div className="col-md-2 text-right">Price</div>
          <div className="col-md-2 text-right">Total</div>
        </div>
      </div>

      <Items
        currency={currency}
        updatingItem={updatingItem}
        updateCartItemQuantity={updateCartItemQuantity}
        removeItemFromCart={removeItemFromCart}
        items={lineItems?.physical_items}
        cartType={cartType}
      />

      {numberItems > 0 && cartType === "full" && (
        <div className="text-right">
          <h2>
            <span>Subtotal: </span>
            <span>
              <CurrencyFormatter currency={currency.code} amount={cartAmount} />
            </span>
          </h2>

          {/* <Link to="/" className="btn btn-outline-dark mr-md-3 mb-3 mb-md-0">
            continue shopping
          </Link> */}
          <Link to="/checkout" className="btn btn-dark">
            Proceed to Checkout
          </Link>
        </div>
      )}
    </div>
  )
}

const Items = props => {
  const { currency, removeItemFromCart, items, cartType } = props

  return (
    <div>
      {items &&
        items.map(item => {
          let itemImage = null

          if (cartType === "full") {
            itemImage = (
              <div className="text-center mr-3">
                <img
                  src={item.image_url}
                  alt={`${item.name}`}
                  className="img-fluid"
                />
                <br />
                <button
                  className="btn btn-link mt-2"
                  onClick={() => removeItemFromCart(item.id)}
                  type="button"
                >
                  Remove
                </button>
              </div>
            )
          }

          const productURL = new URL(item.url)

          return (
            <div className="row border-top pt-3" key={item.id}>
              <div className="col-sm-12 col-md-6 mb-3">
                <div className="d-flex">
                  {itemImage}
                  <div>
                    <h3 className="mb-0">
                      <a href={productURL.pathname}>{item.name}</a>
                    </h3>
                    <small className="text-muted">{item.sku}</small>
                    {item.options.map(option => (
                      <p
                        className="mb-0 text-muted small"
                        key={option.id}
                      >{`${option.name}: ${option.value}`}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-2 mb-3">
                <p className="d-md-none mb-0">Qty</p>
                <AdjustItem {...props} item={item} cartType={cartType} />
              </div>
              <div className="col-sm-4 col-md-2 mb-3 text-md-right">
                <p className="d-md-none mb-0">Price</p>
                <h4 className="mb-0">
                  <CurrencyFormatter
                    currency={currency}
                    amount={item.list_price}
                  />
                </h4>
              </div>
              <div className="col-sm-4 col-md-2 mb-3 text-md-right">
                <p className="d-md-none mb-0">Total</p>
                <h4 className="mb-0">
                  <CurrencyFormatter
                    currency={currency}
                    amount={item.extended_list_price}
                  />
                </h4>
              </div>
            </div>
          )
        })}
    </div>
  )
}

const AdjustItem = props => {
  const { item, updatingItem, cartType } = props
  let minusBtn, plusBtn

  if (cartType === "full") {
    minusBtn = (
      <button
        className="btn btn-dark"
        onClick={() => props.updateCartItemQuantity(item, "minus")}
      >
        -
      </button>
    )

    plusBtn = (
      <button
        className="btn btn-dark"
        onClick={() => props.updateCartItemQuantity(item, "plus")}
      >
        +
      </button>
    )
  }

  return (
    <div className="d-inline-flex align-items-center border">
      {minusBtn}
      {updatingItem === item.id ? (
        <div className="px-3">
          <p className="mb-0">...</p>
        </div>
      ) : (
        <div className="px-3">{item.quantity}</div>
      )}
      {plusBtn}
    </div>
  )
}

export default CartWrapper
