import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import { Nav } from "react-bootstrap"
import Logo from "../../images/logo-image.svg"
import LogoText from "../../images/logo-text-only.svg"
import LogoName from "../../images/logo-with-name.svg"
// import Search from "../../images/search.svg"
import SearchBar from "../search/jsSearch"
import useOutsideClick from "./clickOutside"
import "./header.scss"
import slugify from "slugify"
import CartIcon from "../bigcommerce/CartIcon"
import ProfileIcon from "../bigcommerce/ProfileIcon"
// const searchIndices = [
//   { name: `products`, title: `Products`, hitComp: `PostHit` },
// ]

const Header = ({ type, categories, data }) => {
  const ref = useRef()
  const [showSearch, setShowSearch] = useState()

  useOutsideClick(ref, () => {
    if (showSearch) {
      setShowSearch(false)
    }
    //
  })
  return (
    <header>
      <Sticky
        className="sticky-wrapper"
        stickyClassName="is-sticky"
        stickyStyle={{ transform: "unset", zIndex: "99" }}
      >
        <div className={`header ${type === "home" ? "transparent" : null}`}>
          <div className="container-fluid px-lg-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="header-logo-desktop ml-5 mt-3">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="American Lighting"
                    className="header-logo d-inline-block"
                  />
                  <img
                    src={LogoText}
                    alt="Bright Ideas · Innovative Solutions"
                    className="header-logo-text"
                  />
                </Link>
              </div>
              <div className="header-logo-tablet-mobile ml-5 mt-3">
                <Link to="/">
                  <img src={LogoName} alt="American Lighting" className="" />
                </Link>
              </div>
              <div className="full-header-wrapper">
                <div className="full-header-menu text-center">
                  {categories.map(cat => (
                    <Nav.Item key={cat.id}>
                      <Link to={`/${slugify(cat.title.toLowerCase())}`}>
                        {cat.menuDisplayName}
                      </Link>
                    </Nav.Item>
                  ))}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <ProfileIcon />
                <CartIcon />
                <div
                  className="text-right d-inline-block searchMenu mr-5"
                  style={{ minWidth: "91px" }}
                >
                  <div
                    className="p-relative search-wrapper"
                    style={{ minWidth: "40px", zIndex: 0 }}
                  >
                    <SearchBar
                      products={data}
                      engine={{
                        indexStrategy: "Prefix match",
                        searchSanitizer: "Lower Case",
                        TitleIndex: true,
                        SlugIndex: true,
                        ShortDescIndex: true,
                        LongDescriptionIndex: true,
                        TableIndex: true,
                        SearchByTerm: true,
                      }}
                    />
                  </div>
                  <div className="ml-2 ml-md-4 d-inline-block">
                    <SideBarMenu categories={categories} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}

export default Header
