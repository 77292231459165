import React, { useContext } from "react"
import { Link } from "gatsby"
import { BsFillCartFill } from "react-icons/bs"
import CartContext from "../../context/CartProvider"

const CartIcon = () => {
  const value = useContext(CartContext)
  const numberItems = value?.state?.cart?.numberItems || 0

  return (
    <Link to="/cart" className="mr-2">
      <span className="text-dark" style={{ fontSize: 24 }}>
        <BsFillCartFill />
      </span>
      <span className="badge badge-primary rounded-circle">{numberItems}</span>
    </Link>
  )
}

export default CartIcon
