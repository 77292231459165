import React from "react"
import * as JsSearch from "js-search"
import { Link } from "gatsby"
class ClientSearch extends React.Component {
  state = {
    allProducts: this.props.products.map(t => ({
      ...t,
      longDescription:
        t?._rawLongDesc?.length &&
        t._rawLongDesc.map(
          desc => desc?.children.length && desc?.children[0]?.text
        ),
      table:
        t.orderTable.length &&
        t.orderTable.map(f => f.title.length && f.title.map(n => n.labelInfo)),
    })),
    isLoading: true,
    searchResults: [],
    search: null,
    isError: false,
    indexByTitle: false,
    indexBySlug: false,
    indexByShortDesc: false,
    indexByLongDescription: false,
    indexByTable: false,
    termFrequency: true,
    removeStopWords: false,
    searchQuery: "",
    selectedStrategy: "",
    selectedSanitizer: "",
    showSearch: false,
  }
  /**
   * React lifecycle method that will inject the data into the state.
   */

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.search === null) {
      const { engine } = nextProps
      return {
        indexByTitle: engine.TitleIndex,
        indexBySlug: engine.SlugIndex,
        indexByShortDesc: engine.ShortDescIndex,
        indexByShortDesc: engine.ShortDescIndex,
        indexByLongDescription: engine.LongDescriptionIndex,
        indexByTable: engine.TableIndex,
        termFrequency: engine.SearchByTerm,
        selectedSanitizer: engine.searchSanitizer,
        selectedStrategy: engine.indexStrategy,
      }
    }
    return null
  }
  async componentDidMount() {
    this.rebuildIndex()
  }

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const {
      selectedStrategy,
      selectedSanitizer,
      removeStopWords,
      termFrequency,
      indexByTitle,
      indexBySlug,
      indexByShortDesc,
      indexByLongDescription,
      indexByTable,
    } = this.state
    const products = this.state.allProducts

    const dataToSearch = new JsSearch.Search("id")

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === "All") {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    }
    if (selectedStrategy === "Exact match") {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy()
    }
    if (selectedStrategy === "Prefix match") {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    }

    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === "Case Sensitive"
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id"))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

    // sets the index attribute for the data
    if (indexByTitle) {
      dataToSearch.addIndex("title")
    }
    // sets the index attribute for the data
    if (indexBySlug) {
      dataToSearch.addIndex(["slug", "current"])
    }

    if (indexByShortDesc) {
      dataToSearch.addIndex("shortdesc")
    }
    if (indexByLongDescription) {
      dataToSearch.addIndex("longDescription")
    }
    if (indexByTable) {
      dataToSearch.addIndex("table")
    }

    dataToSearch.addDocuments(products) // adds the data to be searched

    this.setState({ search: dataToSearch, isLoading: false })
  }
  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = e => {
    const { search } = this.state
    const queryResult = search.search(e.target.value)
    this.setState({ searchQuery: e.target.value, searchResults: queryResult })
  }
  handleSubmit = e => {
    e.preventDefault()
  }
  handleReset = e => {
    e.preventDefault()
    this.setState({ showSearch: !this.state.showSearch })
    if (!this.state.showSearch) {
      this.setState({ searchQuery: "" })
    }
  }
  render() {
    const { searchResults, searchQuery, showSearch } = this.state
    const products = this.state.allProducts
    // console.log("products", products)

    const queryResults = searchResults

    // console.log("searchQuery", searchQuery)
    // console.log("this.state", this.state)
    return (
      <>
        <form
          onSubmit={this.handleSubmit}
          className="form-slide-wrapper"
          id="form-slide"
          style={{
            top: `${!showSearch ? "-8px" : "-4px"}`,
          }}
        >
          <input
            value={showSearch ? searchQuery : ""}
            onChange={this.searchData}
            type="text"
            id="Search"
            className={`custom-input-search input ${
              showSearch ? "square" : ""
            }`}
            aria-label="Search"
          />

          <button
            onClick={this.handleReset}
            type="reset"
            className={`search ${showSearch ? "close" : ""}`}
            id="search-btn"
            aria-label="Search"
          ></button>
          {searchQuery && (
            <div>
              <div
                className={`query-result queryList ${
                  !this.state.showSearch ? "d-none" : ""
                }`}
              >
                <ul className="list-unstyled">
                  {queryResults.length ? (
                    queryResults.map((res, index) => (
                      <li key={index}>
                        <Link to={`/${res.slug.current}`}>
                          <p className="mb-0">{res.title}</p>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <p>No results for "{searchQuery}"</p>
                  )}
                </ul>
              </div>
            </div>
          )}
        </form>
      </>
    )
  }
}
export default ClientSearch
