import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import slugify from "slugify"
export default ({ toggle, categories }) => {
	// console.log("toggle", toggle)
	return (
		<div className="navigation">
			<Nav className="flex-column">
				<div>
					<button
						className="close-sidebar"
						onClick={toggle}
						aria-label="Close Menu"
					/>
					<h6 className="menu-title">Products</h6>

					{categories.map(cat => (
						<Nav.Item key={cat.id}>
							<Link to={`/${slugify(cat.title.toLowerCase())}`}>
								{cat.menuDisplayName}
							</Link>
						</Nav.Item>
					))}
				</div>
				<div className="mt-5">
					<h6 className="menu-title">Resources</h6>
					<Nav.Item>
						<Link to="/literature-and-media">Literature & Media</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="/where-to-buy">Where to Buy</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="/contact#contact">Customer Support</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="/contact#contact">Contact Us</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="/contact#company">Company</Link>
					</Nav.Item>
					<Nav.Item>
						<a
							href="http://mail.americanlighting.com:9001/users/sign_in"
							target="_blank"
							rel="noopener noreferrer"
						>
							Rep Login
						</a>
					</Nav.Item>
				</div>
			</Nav>
		</div>
	)
}
