import React from "react"
// import WebriQForm from "@webriq/gatsby-webriq-form"

import "./footer.scss"

import Logo from "@images/logo-image.svg"
import LogoText from "@images/logo-text-centered.svg"
import facebook from "@images/facebook.svg"
import instagram from "@images/instagram.svg"
import linkedin from "@images/linkedin.svg"

import youtube from "@images/youtube.svg"

const footer = ({ footerBottom }) => {
	return (
		<footer style={footerBottom ? { transform: `translateY(100%)` } : null}>
			<div className="footer">
				<div className="container">
					<div className="row no-gutters text-center text-md-left">
						<div className="col-md-6">
							<div className="footer-logo-wrapper">
								<div className="d-block d-md-inline-block">
									<img src={Logo} className="logo-img" alt="" />
								</div>
								<div className="d-block d-md-inline-block">
									<img src={LogoText} className="logo-txt" alt="" />
								</div>
							</div>
							<div className="contacts address text-primary">
								<span className="h6 d-none d-md-block font-weight-bold text-primary text-uppercase mb-md-1">
									AMERICAN LIGHTING INC.
								</span>
								<p className="footer-address text-uppercase">
									<span className="d-block d-md-inline-block">
										11775 EAST 45TH AVENUE.
									</span>{" "}
									DENVER, CO 80239 | <a href="tel:800.880.1180">800.880.1180</a>
								</p>
							</div>
							<div className="socials">
								<a
									href="https://www.facebook.com/americanlightinginc/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={facebook} alt="Facebook" />
								</a>
								<a
									href="https://www.instagram.com/americanlightinginc/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={instagram} alt="Instagram" />
								</a>
								<a
									href="https://www.linkedin.com/company/american-lighting"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={linkedin} alt="LinkedIn" />
								</a>

								<a
									href="https://www.youtube.com/channel/UCjhQVRx5GgDR4bouEOGmfjw"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={youtube} alt="YouTube" />
								</a>
							</div>
						</div>
						<div className="col-md-6">
							<div className="newsletter-subscribe-form-wrapper">
								<p className="subscribe-heading text-white text-uppercase">
									Subscribe to our mailing list
								</p>

								<form
									action="https://americanlighting.us17.list-manage.com/subscribe/post?u=7a3e0f31645429541a97cf551&amp;id=beae1b00d0"
									name="Subscribe"
									method="POST"
									className="subscribe-form"
									data-thank-you-url="/thank-you"
								>
									<div className="input-group d-block d-md-flex">
										<input
											type="email"
											name="MERGE0"
											className="form-control"
											required={true}
											id="footeremail"
											placeholder="Enter your email"
										/>
										<button className="btn subscribe-btn" type="submit">
											JOIN
										</button>
									</div>
								</form>
							</div>
							<div className="contacts phone-emails d-none d-md-block">
								<div className="row">
									<div className="col-6">
										<span className="h6 font-weight-bold text-primary text-uppercase">
											Technical Support
										</span>
										<p>Monday - Friday. 8am - 5pm MDT</p>
										<p>
											<a href="tel:800.285.8051" className="text-white">
												800.285.8051
											</a>
										</p>
										<p>
											<a href="mailto:Technicalsupport@americanlighting.com">
												technicalsupport@americanlighting.com
											</a>
										</p>
									</div>
									<div className="col-6">
										<span className="h6 font-weight-bold text-primary text-uppercase">
											Customer Support
										</span>
										<p>Monday - Friday. 8am - 5pm MDT</p>
										<p>
											<a href="tel:800.285.8051" className="text-white">
												800.285.8051
											</a>
										</p>
										<p>
											<a href="mailto:orders@americanlighting.com">
												orders@americanlighting.com
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="copyright-text text-center text-md-left">
						<span className="text-white">
							{new Date().getFullYear()} © American Lighting, Inc. All rights
							reserved.
						</span>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default footer
