import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header/header"
import Footer from "./footer/footer"
import "bootstrap/dist/css/bootstrap.css"
import "font-awesome/css/font-awesome.min.css"
import "./font-face.css"
import "./layout.scss"
import Notify from "./bigcommerce/Notify"

const Layout = ({ children, type, footerBottom }) => {
  function bindEvent(element, eventName, eventHandler) {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false)
    } else if (element.attachEvent) {
      element.attachEvent("on" + eventName, eventHandler)
    }
  }

  // Send a message to the parent
  const sendMessage = function(msg) {
    // Make sure you are sending a string, and to stringify JSON
    window.parent.postMessage(msg, "*")
  }

  useEffect(() => {
    // console.log("sendBackMessage initiated!")

    bindEvent(window, "message", function(e) {
      // console.log("message received at iFrame!")
      // console.log("e", e)
    })

    let sendBackCount = 1
    const sendBackMessage = setInterval(function() {
      if (sendBackCount > 100) {
        clearInterval(sendBackMessage)
      }

      // console.log("Sending message: ALIVE - Count: " + sendBackCount)
      sendMessage("ALIVE")
      sendBackCount = sendBackCount + 1
    }, 3000)

    // Unload
    return () => {
      // console.log("sendBackMessage unloaded!")
      clearInterval(sendBackMessage)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allSanityCategory(
        filter: { whereToPublish: { eq: "live" } }
        sort: { order: ASC, fields: order }
      ) {
        nodes {
          id
          title
          menuDisplayName
          whereToPublish
          order
        }
      }
      allSanityProduct {
        nodes {
          id
          title
          shortdesc
          _rawLongDesc
          orderTable {
            title {
              labelInfo
            }
          }
          slug {
            current
          }
        }
      }
    }
  `)
  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        type={type}
        categories={data.allSanityCategory.nodes}
        data={data.allSanityProduct.nodes}
      />
      <main>
        <Notify />
        {children}
      </main>
      <Footer footerBottom={footerBottom} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footerBottom: PropTypes.string,
}

export default Layout
