import React, { useContext } from "react"
import { Link } from "gatsby"
import { BsFillPersonLinesFill } from "react-icons/bs"
import CustomerContext from "../../context/CustomerProvider"
import { Dropdown } from "react-bootstrap"

const ProfileIcon = () => {
  const customer_value = useContext(CustomerContext)
  const customer = customer_value?.customer
  const signoutCustomer = customer_value?.signoutCustomer
  const customer_isFetching = customer_value?.isFetching

  return (
    <Dropdown className="mr-2">
      <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
        <BsFillPersonLinesFill className="mr-2" style={{ fontSize: 24 }} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {customer ? (
          <>
            {/* /app/profile */}
            <Dropdown.Item href="#">My Account</Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={signoutCustomer}
              disabled={customer_isFetching}
            >
              Log out
            </Dropdown.Item>
          </>
        ) : (
          <>
            {/* /app/signin /app/register */}
            <Dropdown.Item href="#">Sign In</Dropdown.Item>
            <Dropdown.Item href="#">Register</Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="btn btn-link text-dark"
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
    &#x25bc;
  </button>
))

export default ProfileIcon
